<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nPosRegional"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nPosRegional"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'POSREGIONAL')"
                                :title="getI18n(i18nKey, 'TITLES.regional')"
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'POSREGIONAL')"
                                :title="getI18n(i18nKey, 'TITLES.regional')"
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-form-row>
                    <b-col class="col-sm-12 col-md-4">
                        <b-form-group
                            :label="i18nName"
                            label-for="name-input"
                            class="required label-pdv"
                            :invalid-feedback="i18nRegionalInvalidNameLabel"
                        >
                            <b-form-input
                                id="name-input"
                                v-model.trim="$v.posRegionalModal.name.$model"
                                class="input-pdv-blue"
                                name="name"
                                type="text"
                                :disabled="!isEdit || submitLoading"
                                :state="validateField('name', 'posRegionalModal')"
                                autofocus
                                autocomplete="off"
                                @input="clearResponseError('name', 'posRegionalModal')"
                                @blur="$v.posRegionalModal.name.$touch"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            v-if="isEdit"
                            :label="i18nUserName"
                            label-for="add-user-input"
                            class="label-pdv" >
                            <multiSelectWithService
                                :id="'add-user-input'"
                                ref="userMultiselect"
                                v-model="userName"
                                :service="'users'"
                                :searchable="true"
                                :multiple="false"
                                :disabled="submitLoading"
                                :parameters="usersParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-sm-12 col-md-8">
                        <data-mode-vuetable
                            ref="relationVuetable"
                            v-model="posRegionalModal.users"
                            :fields="userChildrenActionFields"
                            :display-delete-button="isEdit"
                            :disabled="submitLoading"
                            @onClickDelete="handleRemoveUser"
                        />
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="isPosRegionalCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import { minLength, required } from 'vuelidate/lib/validators'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import multiSelectWithService from '@src/components/multiSelectWithService'
import PosRegionalsService from '@src/services/PosRegionalsService'
import userChildrenActionFields from '@src/fields/childrens/userChildrenActionFields'
import cloneObject from '@utils/clone-object'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        CancelButton,
        AddButton,
        SaveButton,
        DataModeVuetable,
        EditLabelButton,
        DeleteLabelButton,
        multiSelectWithService,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
        userChildrenActionFields,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            posRegionalModal: {},
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            userName: '',
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {
        posRegionalModal: {
            name: {
                required,
                minLength: minLength(2),
            },
        },
    },
    computed: {
        isPosRegionalCreated() {
            return this.posRegionalModal.id > 0
        },
        modalOperation() {
            return this.isPosRegionalCreated ? 'edited' : 'created'
        },
        i18nTitleModal() {
            if (this.isPosRegionalCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nPosRegional}`
                }
                return this.posRegionalModal.name
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_regional')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nRegionalInvalidNameLabel() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        i18nUserName() {
            return this.getI18n('USERS.TITLES.user')
        },
        i18nPosRegional() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.regional',
                modifier: 1,
            })
        },
    },
    watch: {
        userName(value) {
            if (value) {
                const index = this.posRegionalModal.users.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.posRegionalModal.users.push(value)
                }
                this.userName = ''
                this.$refs.userMultiselect.$el.focus()
            }
        }
    },
    methods: {
        showModal(data) {
            this.submitLoading = false
            this.deleteLoading = false
            this.userName = ''

            if (data) {
                this.isEdit = false
                this.posRegionalModal = cloneObject(data);
            } else {
                this.isEdit = true
                this.posRegionalModal = {
                    id: 0,
                    name: '',
                    users: [],
                };
            }

            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.posRegionalModal.$touch()
            if (!this.$v.posRegionalModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const operation = this.isPosRegionalCreated
                ? PosRegionalsService.update(
                      this.posRegionalModal.id,
                      this.posRegionalModal
                  )
                : PosRegionalsService.create(this.posRegionalModal)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(
                    this.i18nPosRegional,
                    this.modalOperation,
                    3
                )
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }
            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteRegional)
        },
        async deleteRegional(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.posRegionalModal.$touch()
            this.deleteLoading = true
            const response = await PosRegionalsService.delete(this.posRegionalModal.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nPosRegional, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        async handleRemoveUser(id) {
            const index = this.posRegionalModal.users.findIndex(find => find.id === id);

            if (index > -1) {
                this.posRegionalModal.users.splice(index, 1);
            }
        },
    },
}
</script>
