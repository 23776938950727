<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nBusinessUnit"
                            label-for="filter-business-unit"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-business-unit'"
                                ref="businessUnitMultiselect"
                                v-model="filterModal.business_units"
                                :service="'business-units'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nChannel"
                            label-for="filter-channel"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-channel'"
                                ref="channelMultiselect"
                                v-model="filterModal.channels"
                                :service="'pos-channels'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nChain"
                            label-for="filter-chain"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-chain'"
                                ref="chainMultiselect"
                                v-model="filterModal.chains"
                                :service="'pos-chains'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nRegional"
                            label-for="filter-regional"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-regional'"
                                ref="regionalMultiselect"
                                v-model="filterModal.regionals"
                                :service="'pos-regionals'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nFlag"
                            label-for="filter-flag"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-flag'"
                                ref="flagMultiselect"
                                v-model="filterModal.flags"
                                :service="'pos-flags'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nCategory"
                            label-for="filter-category"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-category'"
                                ref="categoryMultiselect"
                                v-model="filterModal.categories"
                                :service="'pos-categories'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nStateAndCity"
                            label-for="filter-state-city"
                            class="label-pdv">
                            <treeselect
                                :id="'filter-state-city'"
                                ref="filterStateCity"
                                v-model="filterModal.statesAndCities"
                                :options="statesWithCities"
                                :multiple="true"
                                :placeholder="getI18n('PLACEHOLDERS', 'type_to_search')"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import multiSelectWithService from '@src/components/multiSelectWithService'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'
import Treeselect from '@riophae/vue-treeselect'
import statesWithCities from '@src/assets/resources/statesWithCities'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        multiSelectWithService,
        SaveButton,
        ClearFilterButton,
        Treeselect
    },
    mixins: [validationMixin, swalFeedback, fieldsValidation],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            filterModal: {},
            submitLoading: false,
            statesWithCities,
            filterParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
        }
    },
    validations: {},
    computed: {
        i18nChannel() {
            return this.getI18n(i18nKey, 'TITLES.channel')
        },
        i18nChain() {
            return this.getI18n(i18nKey, 'TITLES.chain')
        },
        i18nCategory() {
            return this.getI18n(i18nKey, 'TITLES.category')
        },
        i18nFlag() {
            return this.getI18n(i18nKey, 'TITLES.flag')
        },
        i18nRegional() {
            return this.getI18n(i18nKey, 'TITLES.regional')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nStateAndCity() {
            return this.getI18n(i18nCommon, 'state_and_city')
        },
    },
    methods: {
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                business_units: [],
                channels: [],
                chains: [],
                regionals: [],
                flags: [],
                categories: [],
                statesAndCities: [],
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.filterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
