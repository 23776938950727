<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'BUSINESS_UNITS'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            businessUnitChildrenFields: [
                {
                    key: 'name',
                    label: this.getI18n(i18nKey, 'TITLES.business_unit'),
                    sortable: true,
                },
                {
                    key: 'contract_value',
                    label: this.getI18n(i18nKey, 'TITLES.contract_value'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'contract_time',
                    label: this.getI18n(i18nKey, 'TITLES.contract_time'),
                    sortable: true,
                },
            ],
        }
    },
}
</script>
