import BaseService from '@src/services/BaseService'

class PosRegionalsService extends BaseService {
  constructor() {
    super('pos-regionals')
  }

  removeAllPos(id, params) {
    this.axios.post(`${this.getUrl()}/${id}/remove-all-pos`, params)
  }
}

export default new PosRegionalsService()
